import Vue from 'vue';
import App from './App.vue';

window.addEventListener('DOMContentLoaded', () => {
    let pageMenuInstance = new Vue({
        data() {
            return {
                categoriesData: {},
                categoriesStructure: null,
                selectedParentId: null,
                isAnimationRunning: false,
                resetCategoriesList: false,
                showPageMenu: false,
                showPageMenuBackground: false,
                showPageMenuContent: false,
                showLogo: false,
                showButtons: false,
                showContent: false,
                imgData: null,
                rootColor: null
            }
        },
        methods: {
            createCategoriesObject(array) {
                let structure = [];
                array.forEach(el => {
                    let childrens = null;
                    if (el.children && el.children.length > 0) {
                        childrens = this.createCategoriesObject(el.children);
                    }
                    structure.push({ id: el.id, children: childrens ? childrens : [] });
                    this.categoriesData[el.id] = { ...el };
                    delete this.categoriesData[el.id].children;
                });
                return structure;
            },
            openPageMenu() {
                document.querySelector('body').style.overflow = 'hidden';
                this.showPageMenu = true;
                this.showPageMenuBackground = true;
                setTimeout(() => {
                    document.querySelector('.pageMenuBackground').classList.add('active');
                    setTimeout(() => {
                        document.querySelector('#_top_menu').classList.add('menu-opened');
                        document.querySelector('#language_selector').style.display = "flex";
                        document.querySelector('#_desktop_contact_link').style.display = "flex";
                        this.showPageMenuContent = true;
                        this.showLogo = true;
                        setTimeout(() => {
                            this.showButtons = true;
                            setTimeout(() => {
                                this.showContent = true;
                                setTimeout(() => {
                                    document.querySelector('#language_selector').classList.add('active');
                                    document.querySelector('#_desktop_contact_link').classList.add('active');
                                }, 400);
                            }, 400);
                        }, 400);
                    }, 500);
                }, 50);
            },
            closePageMenu() {
                document.querySelector('#language_selector').classList.remove('active');
                document.querySelector('#_desktop_contact_link').classList.remove('active');
                setTimeout(() => {
                    this.showContent = false;
                    setTimeout(() => {
                        document.querySelector('#_top_menu').classList.remove('menu-opened');
                        this.showButtons = false;
                        setTimeout(() => {
                            this.showLogo = false;
                            setTimeout(() => {
                                this.showPageMenuContent = false;
                                document.querySelector('.pageMenuBackground').classList.remove('active');
                                setTimeout(() => {
                                    this.showPageMenuBackground = false;
                                    this.showPageMenu = false;
                                    document.querySelector('body').style.overflow = '';
                                    document.querySelector('#language_selector').style.display = "";
                                    document.querySelector('#_desktop_contact_link').style.display = "";
                                }, 500);
                            }, 400);
                        }, 400);
                    }, 400);
                }, 200);
            },
            changeImage(id) {
                let imgData = {
                    id: this.categoriesData[id].id,
                    altText: this.categoriesData[id].name,
                    images: this.categoriesData[id].image_urls
                };
                this.imgData = imgData;
            }
        },
        created() {
            this.categoriesStructure = this.createCategoriesObject(window.maalTopMenu);
            this.selectedParentId = this.categoriesStructure[0].id;
            this.changeImage(this.selectedParentId);
            this.rootColor = this.categoriesData[this.selectedParentId].color;
            document.documentElement.style.setProperty('--menuCategoryColor', this.rootColor);
        },
        render: h => h(App)
    }).$mount('#pageMenu');
    window.pageMenu = {
        openPageMenu: pageMenuInstance.openPageMenu,
        closePageMenu: pageMenuInstance.closePageMenu
    };
});