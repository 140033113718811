<template>
  <div v-show="$root.$data.showPageMenu" class="maalPageMenu">
    <div v-if="$root.$data.showPageMenuBackground" class="pageMenuBackground"></div>
    <div v-show="$root.$data.showPageMenuContent" class="pageMenu container">
      <div class="logoWrapper">
        <transition name="menuContentSlide" mode="out-in">
          <a v-if="logoLink" :href="logoLink">
            <img v-show="logo && $root.$data.showLogo" :src="logo" alt="Logo" />
          </a>
        </transition>
        <transition name="menuContentSlide" mode="out-in">
          <div v-show="$root.$data.showButtons" class="buttonsWrapper">
            <button
              type="button"
              class="closeMenuButton"
              aria-label="Close menu"
              @click="$root.closePageMenu"
            >
              <svg
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#closeMenuSvg)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M46.0858 17.4142C46.8668 16.6332 46.8668 15.3668 46.0858 14.5858L45.4142 13.9142C44.6332 13.1332 43.3668 13.1332 42.5858 13.9142L31.4142 25.0858C30.6332 25.8668 29.3668 25.8668 28.5858 25.0858L17.4142 13.9142C16.6332 13.1332 15.3668 13.1332 14.5858 13.9142L13.9142 14.5858C13.1332 15.3668 13.1332 16.6332 13.9142 17.4142L25.0858 28.5858C25.8668 29.3668 25.8668 30.6332 25.0858 31.4142L13.9142 42.5858C13.1332 43.3668 13.1332 44.6332 13.9142 45.4142L14.5858 46.0858C15.3668 46.8668 16.6332 46.8668 17.4142 46.0858L28.5858 34.9142C29.3668 34.1332 30.6332 34.1332 31.4142 34.9142L42.5858 46.0858C43.3668 46.8668 44.6332 46.8668 45.4142 46.0858L46.0858 45.4142C46.8668 44.6332 46.8668 43.3668 46.0858 42.5858L34.9142 31.4142C34.1332 30.6332 34.1332 29.3668 34.9142 28.5858L46.0858 17.4142Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <filter
                    id="closeMenuSvg"
                    x="7.32837"
                    y="7.32843"
                    width="45.3431"
                    height="45.3431"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="3" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                    />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </button>
          </div>
        </transition>
      </div>
      <transition name="menuContentSlide" mode="out-in">
        <div v-show="$root.$data.showContent" class="contentWrapper">
          <div
            v-if="$root.$data.categoriesStructure && $root.$data.categoriesStructure.length > 0"
            class="categoriesList depth1 desktop"
            ref="categoriesList"
          >
            <div class="itemsWrapper depth1">
              <CategoriesList
                v-for="(item, index) in $root.$data.categoriesStructure"
                :key="`item${index}${$root.$data.resetCategoriesList}`"
                :index="index"
                :item="item"
                :depth="1"
                :parentId="item.id"
              ></CategoriesList>
            </div>
          </div>
          <div
            v-if="$root.$data.categoriesStructure && $root.$data.categoriesStructure.length > 0"
            class="categoriesList depth1 mobile"
          >
            <div class="itemsWrapper">
              <CategoriesListMobile
                v-for="(item, index) in $root.$data.categoriesStructure"
                :key="`item${index}`"
                :item="item"
              ></CategoriesListMobile>
            </div>
          </div>
          <transition name="menuContentSlide">
            <div
              v-if="$root.$data.imgData && $root.$data.imgData.images != null && $root.$data.imgData.images.bySize"
              class="categoryImageWrapper"
              :key="$root.$data.imgData.id"
            >
              <img :src="$root.$data.imgData.images.bySize.category_default.url" :alt="$root.$data.imgData.altText" />
            </div>
          </transition>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logo: null,
      logoLink: null
    };
  },
  watch: {
    "$root.$data.showContent": function() {
      this.setContentWrapperHeight();
    }
  },
  components: {
    CategoriesList: () => import("./components/CategoriesList.vue"),
    CategoriesListMobile: () => import("./components/CategoriesListMobile.vue")
  },
  methods: {
    setContentWrapperHeight() {
      let listHeight = this.$refs.categoriesList.parentNode.offsetHeight - 95;
      if (listHeight > 530) {
        listHeight = 530;
      }
      $("head").append('<style type="text/css"></style>');
      var newStyleElement = $("head").children(":last");
      newStyleElement.html(
        `.maalPageMenu .pageMenu .contentWrapper .categoriesList:not(.depth1) { max-height: ${listHeight}px !important; }`
      );
    }
  },
  mounted() {
    this.logo = window.prestashopLogoSrc;
    this.logoLink = window.prestashop.urls.pages.index;
  }
};
</script>

<style lang="scss">
#header .maalPageMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 91;

  .menuContentSlide-enter-active,
  .menuContentSlide-leave-active {
    transition: opacity 0.5s, transform 0.5s;
  }

  .menuContentSlide-enter,
  .menuContentSlide-leave-to {
    opacity: 0;
    transform: translateY(-100%);
  }

  .pageMenuBackground {
    position: fixed;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    background-color: #f8f8f8;
    border-radius: 100%;
    transform: translate3d(50%, -50%, 0);
    transition: width 0.5s, height 0.5s;
    box-shadow: 0px 19px 23px rgba(0, 0, 0, 0.06);

    &.active {
      @media (max-aspect-ratio: 1/1) {
        width: 300vh;
        height: 300vh;
      }
      @media (min-aspect-ratio: 1/1) {
        width: 300vw;
        height: 300vw;
      }
    }
  }

  .categoryItemSlide-enter-active,
  .categoryItemSlide-leave-active {
    transition: transform 0.5s, opacity 0.5s;
  }

  .categoryItemSlide-enter,
  .categoryItemSlide-leave-to {
    transform: translateX(-100%);
    opacity: 0;
  }

  .categoriesListSlide-enter-active,
  .categoriesListSlide-leave-active {
    transition: transform 0.3s, opacity 0.3s;
  }

  .categoriesListSlide-enter,
  .categoriesListSlide-leave-to {
    transform: translateX(100%);
    opacity: 0;
  }

  p {
    margin: 0;
  }

  .pageMenu {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 100vw;
      height: 200px;
      background-color: var(--menuCategoryColor);
      transition: background-color 0.3s;
      z-index: 1;
    }

    @media (max-width: 999px) {
      padding: 0;

      &::after {
        height: 120px;
      }
    }
    @media (max-width: 599px) {
      &::after {
        height: 78px;
      }
    }

    .logoWrapper {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 60px 0;
      z-index: 2;

      img {
        width: 200px;
        height: 80px;
      }

      .buttonsWrapper {
        height: 60px;
        position: absolute;
        top: 50%;
        right: 60px;
        transform: translateY(-50%);

        button {
          width: 60px;
          height: 60px;
          border: none;
          outline: none;
          background-color: transparent;
          padding: 0;
          cursor: pointer;

          &.closeMenuButton {
            margin-left: 15px;
          }
        }
      }

      @media (max-width: 999px) {
        padding: 20px 0;
        .buttonsWrapper {
          right: 15px;
        }
      }

      @media (max-width: 599px) {
        justify-content: space-between;
        align-items: center;
        padding: 15px;

        img {
          width: 100%;
          max-width: 117px;
          height: 100%;
          max-height: 120px;
        }

        .buttonsWrapper {
          position: relative;
          top: 0;
          right: 0;
          transform: translateY(0);
          height: 46px;

          button {
            width: 46px;
            height: 46px;

            svg {
              width: 46px;
              height: 46px;
            }

            &.closeMenuButton {
              margin-left: 10px;
            }
          }
        }
      }
    }

    .contentWrapper {
      position: relative;
      width: 100%;
      height: calc(100% - 200px);
      padding: 15px;
      z-index: 1;

      .categoryImageWrapper {
        position: absolute;
        width: 40%;
        height: 35vw;
        max-height: 100%;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center center;
        }

        @media (max-width: 999px) {
          display: none;
        }
      }

      .categoriesListMobile {
        position: relative;
        padding-left: 15px;

        &::after {
          content: "";
          width: 1px;
          height: calc(100% + 4px);
          background-color: #e0e0e0;
          position: absolute;
          top: -4px;
          left: 0;
        }
      }

      .categoriesList.depth1.mobile {
        height: 100%;
        overflow-y: scroll;
      }

      .itemInfoWrapperMobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 46px;
        border-bottom: 1px solid #e0e0e0;
        margin-bottom: 4px;

        a {
          font-size: 20px;
          line-height: 26px;
          font-weight: 400;
          color: #212121;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 10px;
        }

        button {
          width: 46px;
          height: 46px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          background-color: transparent;
          border: none;
          outline: none;
          cursor: pointer;

          svg {
            width: 40px;
            height: 40px;
            transform: rotate(90deg);
            transition: transform 0.3s;

            &.active {
              transform: rotate(-90deg);
            }
          }
        }

        @media (max-width: 599px) {
          height: 36px;

          a {
            font-size: 16px;
            line-height: 22px;
          }

          button {
            width: 36px;
            height: 36px;

            svg {
              width: 30px;
              height: 30px;
            }
          }
        }
      }

      .categoriesList {
        &.depth1 {
          @media (max-width: 999px) {
            &.desktop {
              display: none;
            }

            &.mobile {
              display: block;
            }
          }

          @media (min-width: 1000px) {
            &.desktop {
              display: block;
            }

            &.mobile {
              display: none;
            }
          }
        }

        .itemsWrapper {
          width: 100%;
          min-height: 100%;

          &.depth1 {
            display: flex;
            border-bottom: 1px solid #6e6e6e;
            overflow-x: scroll;

            .categoryName {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            &::-webkit-scrollbar {
              width: 0px;
              height: 3px;
            }

            &::-webkit-scrollbar-track {
              background: #f8f8f8;
            }

            &::-webkit-scrollbar-thumb {
              background: #888;
            }

            &::-webkit-scrollbar-thumb:hover {
              background: #6e6e6e;
            }
          }
        }

        &:not(.depth1) {
          width: 100%;
          max-width: 400px;
          height: 100%;
          max-height: 530px;
          overflow-y: scroll;
          padding: 0 8px 0 0;
          position: absolute;
          top: 80px;
          left: 15px;

          &:not(.depth2) {
            top: 0;
            left: 55px;
          }

          &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
          }

          &::-webkit-scrollbar-track {
            background: #f8f8f8;
          }

          &::-webkit-scrollbar-thumb {
            background: #888;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: #555;
          }

          .itemsWrapper {
            padding: 10px 0 30px 10px;

            .categoriesItem {
              .itemInfoWrapper {
                .categoryName {
                  text-decoration: none;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  width: calc(100% - 50px);
                }
              }
            }
          }
        }

        .itemsWrapper {
          .categoriesItem {
            &.depth1 {
              display: flex;
              justify-content: center;
              align-items: center;
            }

            &:not(.depth1) {
              margin-bottom: 25px;

              &:last-child {
                margin-bottom: 0;
              }
            }

            .itemInfoWrapper {
              display: flex;
              justify-content: center;
              align-items: center;

              .categoryName {
                display: inline-block;
                font-size: 20px;
                line-height: 26px;
                height: 26px;
                font-weight: 300;
                color: #212121;
                transition: font-size 0.2s;

                &.depth1 {
                  margin: 0 15px;
                  font-weight: 400;
                  cursor: pointer;

                  &.active {
                    display: inline-block;
                    height: 40px;
                    font-size: 30px;
                    line-height: 40px;
                    font-weight: 500;
                  }
                }
              }

              &:not(.depth1) {
                justify-content: initial;
                min-height: 40px;
                margin-bottom: 25px;

                .categoryName {
                  line-height: 34px;
                  height: 38px;
                  padding: 0 30px;
                  background-color: #ffffff;
                  box-shadow: 0px 19px 23px rgba(0, 0, 0, 0.06);
                  border-radius: 0;
                  transition: border 0.2s;
                  border: 2px solid #ffffff;
                  cursor: pointer;

                  &:hover,
                  &:focus {
                    border: 2px solid var(--menuCategoryColor);
                  }
                }
              }

              &:last-child {
                margin-bottom: 0;
              }

              .openSubcategories {
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                padding: 0;
                border: none;
                border-radius: 0;
                margin-left: 10px;
                outline: none;
                background-color: #ffffff;
                box-shadow: 0px 19px 23px rgba(0, 0, 0, 0.06);
                cursor: pointer;
                transition: border 0.2s;
                border: 2px solid #ffffff;

                &:hover,
                &:focus {
                  border: 2px solid var(--menuCategoryColor);
                }
              }
            }

            &:first-child {
              .categoryName {
                &.depth1 {
                  margin-left: 0;
                }
              }
            }

            &:last-child {
              .categoryName {
                &.depth1 {
                  margin-right: 0;
                }
              }
            }
          }
        }
      }

      @media (max-width: 999px) {
        height: calc(100% - 260px);
      }

      @media (max-width: 599px) {
        height: calc(100% - 180px);
      }
    }
  }
}
</style>